import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

    const shopifyProductId = '7106201551015'
    const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

    const sizes = {
        "HALF WAIST WIDTH": [
            "",
            "13/33",
            "13¾/35",
            "14½/37",
            "15¼/39",
            "16/41",
            "17/43",
            "17¾/45",
            ""
        ],
        "HALF HIPS WIDTH": [
            "",
            "15¼/39",
            "16/41",
            "17/43",
            "17¾/45",
            "18½/47",
            "19/49",
            "20/51",
            ""
        ],
        "INSIDE LEG LENGTH": [
            "",
            "10/25,5",
            "10¼/26",
            "10½/26,50",
            "10½/27",
            "10¾/27,5",
            "11/28",
            "11¼/28,5",
            ""
        ]
    }


    return (
        <Layout>
            <SEO title="Shop - Carmine Short" />

            <ProductV2 productId={base64ProductId} sizes={sizes} />
        </Layout>
    )

} export default ProductShopify